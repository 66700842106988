<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.06066"
      y1="13.2028"
      x2="8.67566"
      y2="20.8178"
      stroke="white"
      stroke-width="3"
    />
    <line
      x1="6.55433"
      y1="20.8178"
      x2="20.6965"
      y2="6.67562"
      stroke="white"
      stroke-width="3"
    />
  </svg>
</template>
