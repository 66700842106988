<script setup>
import { computed, ref } from "vue";
import DataLoading from "@/components/DataLoading.vue";
import SelectFields from "@/components/SelectFields.vue";
import PortalHeader from "@/components/PortalHeader.vue";
import MarkIcon from "@/components/icons/MarkIcon.vue";
import PortalPopover from "@/components/PortalPopover.vue";

const windowActive = ref(false);

const selectFieldActive = ref(false);

const closeSelectFields = () => {
  selectFieldActive.value = false;
};

const left = ref(0);
const top = ref(0);

const popoverActive = ref(false);

const popover = ref(null);

const portalOk = ref();

const showPopover = () => {
  top.value = portalOk.value.getBoundingClientRect().top + 20;
  left.value = portalOk.value.getBoundingClientRect().left - 380;
  popoverActive.value = true;
};

const hidePopover = () => {
  popoverActive.value = false;
};

const popoverPosition = computed(() => ({
  left: left.value + "px",
  top: top.value + "px",
}));
</script>

<template>
  <PortalHeader />
  <section class="container">
    <PortalPopover
      v-if="popoverActive"
      :style="popoverPosition"
      @hidePopover="hidePopover"
      ref="popover"
    />
    <div
      class="block block--relative hidden"
      :class="{ 'block--active': windowActive }"
    >
      <div>
        <div class="block__head">
          <h1 class="title">Настройка порталов</h1>
          <div class="buttons-block">
            <button class="buttons-block__btn">
              <img src="../assets/img/info.svg" alt="" />
            </button>
            <button
              @click="windowActive = !windowActive"
              class="buttons-block__btn"
              :class="{ 'buttons-block__btn--active': windowActive }"
            >
              <img src="../assets/img/open-arrow.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div
        :class="{ 'block__forms--active': windowActive }"
        class="block__forms"
      >
        <div class="form">
          <input type="text" class="form__input" />
          <!--          <button class="form__btn">-->
          <!--            {{ $t("synchronizationItem.unlinkBtn") }}-->
          <!--          </button>-->
          <button
            @click="showPopover()"
            ref="portalOk"
            class="portal-ok-btn portal-warning-btn"
          >
            !
          </button>
        </div>
        <div class="form">
          <input type="text" class="form__input form__input--success" />
          <button class="portal-ok-btn">
            <MarkIcon />
          </button>
          <!--          <button class="form__btn">-->
          <!--            {{ $t("synchronizationItem.unlinkBtn") }}-->
          <!--          </button>-->
        </div>
      </div>
    </div>
    <div class="block">
      <div class="radio-buttons">
        <div class="radio-buttons__btn">
          <input id="radio-1" type="radio" name="radio" value="1" checked />
          <label for="radio-1">Сделки</label>
        </div>

        <div class="radio-buttons__btn">
          <input id="radio-2" type="radio" name="radio" value="2" />
          <label for="radio-2">Лиды</label>
        </div>

        <div class="radio-buttons__btn">
          <input id="radio-3" type="radio" name="radio" value="3" />
          <label for="radio-3">Компании</label>
        </div>
        <div class="radio-buttons__btn">
          <input id="radio-4" type="radio" name="radio" value="4" />
          <label for="radio-4">Контакты</label>
        </div>
        <div class="radio-buttons__btn">
          <input id="radio-5" type="radio" name="radio" value="5" />
          <label for="radio-5">Задачи</label>
        </div>
      </div>
      <div class="unloading">
        <div class="unloading__choice">
          <p>Порядок выгрузки:</p>
          <ul>
            <li class="unloading__choice-item">
              <input type="radio" id="radio-6" />
              <label for="radio-6">Выгружать на 1 портал</label>
            </li>
            <li class="unloading__choice-item">
              <input type="radio" id="radio-7" />
              <label for="radio-7">Выгружать на 2 портал</label>
            </li>
            <li class="unloading__choice-item">
              <input type="radio" id="radio-8" />
              <label for="radio-8">Двухсторонняя синхронизация</label>
            </li>
            <li class="unloading__choice-item">
              <input type="radio" id="radio-9" />
              <label for="radio-9">Не выгружать</label>
            </li>
          </ul>
        </div>
        <div class="unloading__buttons">
          <button class="unloading__buttons-btn">
            Распределить автоматически
          </button>
          <button
            class="unloading__buttons-btn unloading__buttons-btn--warning"
          >
            Очистить
          </button>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="table__row">
        <div class="table__item">
          <p class="table__text">Поле в первом портале</p>
        </div>
        <div class="table__item">
          <p class="table__text">Поле во втором портале</p>
        </div>
      </div>
      <div class="table__row">
        <div class="table__item">
          <p class="table__text">24.06.2024</p>
        </div>
        <div class="table__item">
          <button @click="selectFieldActive = true" class="table__btn">
            выбрать поле
          </button>
        </div>
      </div>
      <div class="table__row">
        <div class="table__item">
          <p class="table__text">24.06.2024</p>
        </div>
        <div class="table__item">
          <p class="table__text">Ответственный</p>
        </div>
      </div>
      <div class="table__row">
        <div class="table__item">
          <p class="table__text">24.06.2024</p>
        </div>
        <div class="table__item">
          <p class="table__text">
            Ответственный; Код груза; Документация приложения;...
          </p>
          <button class="table__btn">ещё</button>
        </div>
      </div>

      <div class="table__row">
        <div class="table__item">
          <DataLoading />
        </div>
      </div>

      <button class="table__btn table__btn--mt12">
        {{ $t("table.moreBtn") }}
      </button>
    </div>
  </section>
  <SelectFields
    @close-select-fields="closeSelectFields"
    :select-fields-active="selectFieldActive"
  />
</template>

<style scoped>
.container {
  width: 90%;
  margin: auto;
  min-height: 100vh;
}

.title {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: start;
}

.hidden {
  overflow: hidden;
}

.block {
  background: rgb(0, 111, 224);
  background: linear-gradient(
    90deg,
    rgba(0, 111, 224, 1) 0%,
    rgba(1, 33, 160, 1) 100%
  );
  padding: 20px 24px;
  border-radius: 12px;
  margin-bottom: 12px;
  transition: 1s;
}

.block--active {
  padding-bottom: 180px;
}

.block--relative {
  position: relative;
}

.block__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-block {
  width: min-content;
  display: flex;
}

.buttons-block__btn {
  background-color: rgba(255, 255, 255, 0.13);
  outline: none;
  border: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 12px;
}

.buttons-block__btn--active {
  transform: rotate(-180deg);
}

.buttons-block__btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.block__forms {
  position: absolute;
  width: 100%;
  height: min-content;
  background: rgb(0, 111, 224);
  background: linear-gradient(
    90deg,
    rgba(0, 111, 224, 1) 0%,
    rgba(1, 33, 160, 1) 100%
  );
  padding: 20px 24px;
  border-radius: 0 0 12px 12px;
  box-sizing: border-box;
  left: 0;
  margin-top: 20px;
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.form__input {
  width: 100%;
  padding: 13px 10px;
  margin-right: 8px;
  border: 2px solid transparent;
  border-radius: 28px;
  outline: none;
  transition: 0.05s;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.form__input--warning {
  border: 2px solid #f0717d;
}

.form__input--success {
  border: 2px solid #9dcf00;
}

.form__btn::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.form__input:focus {
  border: 2px solid #c6cdd3;
}

.form__btn {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 28px;
  padding: 0 10px;
  cursor: pointer;
  background-color: #14bfd5;
  transition: 0.3s;
}

.form__btn:hover {
  background-color: #43ccde;
}
.radio-buttons {
  background-color: rgba(255, 255, 255, 0.16);
  display: flex;
  border-radius: 28px;
  padding: 4px 6px;
  flex-wrap: wrap;
  max-width: 505px;
  margin-bottom: 20px;
}

.radio-buttons__btn {
  display: inline-block;
  margin-right: 10px;
}
.radio-buttons__btn input[type="radio"] {
  display: none;
  transition: 0.3s;
}
.radio-buttons__btn label {
  font-family: "Roboto", sans-serif;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  cursor: pointer;
  padding: 0 15px;
  line-height: 34px;
  border-radius: 6px;
  user-select: none;
  background: inherit;
  white-space: nowrap;
  transition: 0.3s;
}

.radio-buttons__btn input[type="radio"]:checked + label {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline #ffffff;
}

.radio-buttons__btn label:hover {
  color: rgba(255, 255, 255, 1);
}

.unloading {
  display: flex;
  justify-content: space-between;
}

.unloading__choice {
  display: flex;
}

.unloading__choice p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #ffffff;
  margin-left: 15px;
}

.unloading__choice-item {
  margin-bottom: 10px;
  list-style: none;
  text-align: start;
}

.unloading__choice-item label {
  margin-left: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #ffffff;
}

.unloading__buttons {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.unloading__buttons-btn {
  padding: 8px 14px;
  cursor: pointer;
  background-color: inherit;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  outline: none;
  margin-top: 8px;
  width: min-content;
  white-space: nowrap;
}

.unloading__buttons-btn--warning {
  color: #f0717d;
  border-color: #f0717d;
}
.table {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 16px;
  border: 1px solid #232f3f;
  background: rgb(0, 111, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 111, 226, 1) 0%,
    rgba(1, 33, 168, 1) 100%
  );
  box-sizing: border-box;

  padding: 20px 24px;
}

.table__row {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 1px solid #ffffff;
}

.table__item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 8px;
}

.table__item--end {
  align-items: end;
}

.table__text {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 8px;
  text-align: start;
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.table__btn {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background-color: inherit;
  outline: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  margin: 0;
}

.table__btn--mt12 {
  margin-top: 12px;
}

.portal-ok-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #9dcf00;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.portal-warning-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #f0717d;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 22px;
  color: #ffffff;
}
</style>
