import { createRouter, createWebHistory } from "vue-router";
import ApplicationSettings from "@/pages/ApplicationSettings";
import SynchronizationItem from "@/pages/SynchronizationItem";
import SynchronizationTable from "@/pages/SynchronizationTable";
import PortalSettings from "@/pages/PortalSettings.vue";
import PortalHistory from "@/pages/PortalHistory.vue";

const routes = [
  {
    path: "/",
    name: "Figma",
    component: SynchronizationItem,
  },
  {
    path: "/sync",
    name: "Table",
    component: SynchronizationTable,
  },
  {
    path: "/settings",
    name: "Settings",
    component: ApplicationSettings,
  },
  {
    path: "/portal-settings",
    name: "Portal Settings",
    component: PortalSettings,
  },
  {
    path: "/portal-history",
    name: "Portal History",
    component: PortalHistory,
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
