<template>
  <SyncHeader />
  <section class="container">
    <div class="card">
      <div class="card__info">
        <div>
          <h1 class="card__info-title">
            {{ $t("appSettings.projectOrGroup.title") }}
          </h1>
          <p class="card__info-text">
            {{ $t("appSettings.projectOrGroup.desc") }}
          </p>
        </div>
        <div class="btn-block" v-if="isLoading">
          <DataLoading />
        </div>
        <div class="btn-block" v-else>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': config?.sonet_group }"
            @click="toggleConfigValue('sonet_group')"
          >
            {{ $t("appSettings.displayBtn") }}
          </button>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': !config?.sonet_group }"
            @click="toggleConfigValue('sonet_group')"
          >
            {{ $t("appSettings.noDisplayBtn") }}
          </button>
        </div>
      </div>
      <div class="slider">
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[0]--">
            <img src="../assets/img/left.svg" alt="left" />
          </button>
        </div>
        <carousel :items-to-show="1" v-model="currentSlide[0]">
          <slide class="slider__slide" v-for="slide in 10" :key="slide">
            <img
              class="slider__slide-img"
              src="https://jpeg.org/images/jpeg-home.jpg"
              alt=""
            />
          </slide>

          <template #addons>
            <pagination />
          </template>
        </carousel>
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[0]++">
            <img src="../assets/img/right.svg" alt="left" />
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card__info">
        <div>
          <h1 class="card__info-title">{{ $t("appSettings.task.title") }}</h1>
          <p class="card__info-text">
            {{ $t("appSettings.task.desc") }}
          </p>
        </div>
        <div class="btn-block" v-if="isLoading">
          <DataLoading />
        </div>
        <div class="btn-block" v-else>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': config?.item }"
            @click="toggleConfigValue('item')"
          >
            {{ $t("appSettings.displayBtn") }}
          </button>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': !config?.item }"
            @click="toggleConfigValue('item')"
          >
            {{ $t("appSettings.noDisplayBtn") }}
          </button>
        </div>
      </div>
      <div class="slider">
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[1]--">
            <img src="../assets/img/left.svg" alt="left" />
          </button>
        </div>
        <carousel :items-to-show="1" v-model="currentSlide[1]">
          <slide class="slider__slide" v-for="slide in 10" :key="slide">
            <img
              class="slider__slide-img"
              src="https://cdn.fstoppers.com/styles/full/s3/media/2019/12/04/nando-jpeg-quality-001.jpg"
              alt=""
            />
          </slide>

          <template #addons>
            <pagination />
          </template>
        </carousel>
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[1]++">
            <img src="../assets/img/right.svg" alt="left" />
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card__info">
        <div>
          <h1 class="card__info-title">
            {{ $t("appSettings.deals.title") }}
          </h1>
          <p class="card__info-text">
            {{ $t("appSettings.deals.desc") }}
          </p>
        </div>
        <div class="btn-block" v-if="isLoading">
          <DataLoading />
        </div>
        <div class="btn-block" v-else>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': config?.deal }"
            @click="toggleConfigValue('deal')"
          >
            {{ $t("appSettings.displayBtn") }}
          </button>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': !config?.deal }"
            @click="toggleConfigValue('deal')"
          >
            {{ $t("appSettings.noDisplayBtn") }}
          </button>
        </div>
      </div>
      <div class="slider">
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[2]--">
            <img src="../assets/img/left.svg" alt="left" />
          </button>
        </div>
        <carousel :items-to-show="1" v-model="currentSlide[2]">
          <slide class="slider__slide" v-for="slide in 10" :key="slide">
            <img
              class="slider__slide-img"
              src="https://cdn.fstoppers.com/styles/full/s3/media/2019/12/04/nando-jpeg-quality-001.jpg"
              alt=""
            />
          </slide>

          <template #addons>
            <pagination />
          </template>
        </carousel>
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[2]++">
            <img src="../assets/img/right.svg" alt="left" />
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card__info">
        <div>
          <h1 class="card__info-title">
            {{ $t("appSettings.smartProcesses.title") }}
          </h1>
          <p class="card__info-text">
            {{ $t("appSettings.smartProcesses.desc") }}
          </p>
        </div>
        <div class="btn-block" v-if="isLoading">
          <DataLoading />
        </div>
        <div class="btn-block" v-else>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': config?.crm_type }"
            @click="toggleConfigValue('crm_type')"
          >
            {{ $t("appSettings.displayBtn") }}
          </button>
          <button
            class="card__info-btn"
            :class="{ 'card__info-btn--bordered': !config?.crm_type }"
            @click="toggleConfigValue('crm_type')"
          >
            {{ $t("appSettings.noDisplayBtn") }}
          </button>
        </div>
      </div>
      <div class="slider">
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[2]--">
            <img src="../assets/img/left.svg" alt="left" />
          </button>
        </div>
        <carousel :items-to-show="1" v-model="currentSlide[2]">
          <slide class="slider__slide" v-for="slide in 10" :key="slide">
            <img
              class="slider__slide-img"
              src="https://cdn.fstoppers.com/styles/full/s3/media/2019/12/04/nando-jpeg-quality-001.jpg"
              alt=""
            />
          </slide>

          <template #addons>
            <pagination />
          </template>
        </carousel>
        <div class="navigation-wrapper">
          <button class="slider_navigation-btn" @click="currentSlide[2]++">
            <img src="../assets/img/right.svg" alt="left" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import SyncHeader from "@/components/SyncHeader.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { ref } from "vue";
import { queryGet, queryPost } from "@/api/config";
import DataLoading from "@/components/DataLoading.vue";

const config = ref(null);
const isLoading = ref(true);
//
// watch(
//   config,
//   async (newValue) => {
//     isLoading.value = true;
//     await setConfig(newValue);
//   },
//   {
//     deep: true,
//   },
// );
const portalDomain = ref(null);

const fitWindow = async () => {
  try {
    window.BX24.fitWindow();
    console.log('fitWindow()');
  } catch (error) {
    // Если возникла ошибка, просто пропускаем её без обработки
    console.error('Ошибка при вызове window.BX24.fitWindow():');
  }
};

const getConfig = async () => {
  try {
    window.BX24.init(async function () {
      portalDomain.value = window.BX24.getDomain();
      console.log(portalDomain.value);

      const res = await queryGet("/client/config", {
        params: {
          domain: portalDomain.value,
        },
      });

      if (res.status === 200) {
        config.value = res.data;
        isLoading.value = false;
      }
    });
  } catch (error) {
    portalDomain.value = "b24-tpalhm.bitrix24.ru";
    console.log(error);

    const res = await queryGet("/client/config", {
      params: {
        domain: portalDomain.value,
      },
    });

    if (res.status === 200) {
      config.value = res.data;
      isLoading.value = false;
      await fitWindow();
    }
  }
};

const setConfig = async () => {
  const res = await queryPost("/client/config", config.value, {
    params: {
      domain: portalDomain.value,
    },
  });
  if (res.status === 200) {
    await getConfig();
    await fitWindow();
  }
};

const toggleConfigValue = async (value) => {
  config.value[value] = !config.value[value];
  isLoading.value = true;
  await setConfig();
  await fitWindow();
};

const currentSlide = ref([0, 0, 0]);
await fitWindow();
getConfig();
</script>

<style>
.container {
  width: 90%;
  margin: auto;
  min-height: 100vh;
}

.card {
  width: 100%;
  min-height: 250px;
  background: rgb(0, 111, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 111, 226, 1) 0%,
    rgba(1, 33, 168, 1) 100%
  );
  border-radius: 12px;
  padding: 20px 24px;
  display: flex;
  margin-bottom: 12px;
}

.card__info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__info-title {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 18px;
  text-align: start;
  margin-bottom: 20px;
  max-width: 350px;
}

.card__info-text {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 14px;
  text-align: start;
  max-width: 350px;
}

.btn-block {
  width: min-content;
  display: flex;
}

.card__info-btn {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 14px;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  width: min-content;
  padding: 6px 12px;
  box-sizing: border-box;
  margin-right: 12px;
  white-space: nowrap;
}

.card__info-btn--bordered {
  border: 1px solid #ffffff;
  border-radius: 4px;
}

.slider {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: stretch;
}

.slider__slide {
  height: 100%;
  width: 100%;
}

.slider__slide-img {
  width: 100%;
  border-radius: 8px;
}

.navigation-wrapper {
  display: flex;
  align-items: center;
}

.slider_navigation-btn {
  cursor: pointer;
  background-color: inherit;
  border: none;
  outline: none;
}

.carousel {
  height: 100%;
  width: 100%;
  max-width: 350px;
}

.carousel__pagination {
  margin: 0;
}

.carousel__pagination-button::after {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff29;
}

.carousel__pagination-button--active::after {
  background-color: #ffffff;
}

@media (hover: hover) {
  .carousel__pagination-button:hover::after {
    background-color: #ffffff;
  }
}
</style>
