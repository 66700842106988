import axios from "axios";

export const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 100000,
  auth: {
    username: "figma",
    password: ")8y$szcp<D8aZ7q[emq^1X+vM]koh]9M",
    // eslint-disable-next-line no-return-assign
  },
  headers: {
    "X-Custom-Header": "foobar",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": process.env.VUE_APP_BACKEND_URL,
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
});

export default apiInstance;

export const queryGet = (url, config = {}) => apiInstance.get(url, config);

export const queryPost = (url, data = null, config = {}) =>
  apiInstance.post(url, data, config);

export const queryPatch = (url, data = null, config = {}) =>
  apiInstance.patch(url, data, config);

export const queryDelete = (url, config = {}) =>
  apiInstance.delete(url, config);
