<template>
  <header class="header">
    <nav class="menu">
      <ul class="menu__list">
        <li class="menu__list-item">
          <RouterLink
            active-class="menu__list-link--active"
            class="menu__list-link"
            to="/portal-settings"
            >Настройки</RouterLink
          >
        </li>
        <li class="menu__list-item">
          <RouterLink
            active-class="menu__list-link--active"
            class="menu__list-link"
            to="/portal-history"
            >История</RouterLink
          >
        </li>
      </ul>
    </nav>
  </header>
</template>
<style>
.header {
  width: 90%;
  margin: 0 auto 20px;
}

.menu {
  width: min-content;
}

.menu__list {
  display: flex;
  padding: 0;
}

.menu__list-item {
  list-style: none;
  margin-right: 16px;
}

.menu__list-link {
  width: min-content;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  padding: 4px 8px;
  text-decoration: none;
  color: #80868e;
}

.menu__list-link--active {
  color: #185ccd;
}
</style>
