<template>
  <SyncHeader />
  <section class="container">
    <div class="table">
      <div class="table__row">
        <div class="table__item">
          <p class="table__text">{{ $t("table.head.type") }}</p>
        </div>
        <div class="table__item">
          <p class="table__text">{{ $t("table.head.objectId") }}</p>
          <div class="table__item-wrapper">
            <input
              class="table__item-input"
              v-model="objectIdFilter"
              type="text"
            />
          </div>
        </div>
        <div class="table__item">
          <p class="table__text">{{ $t("table.head.figmaLink") }}</p>
          <div class="table__item-wrapper">
            <input
              class="table__item-input"
              v-model="figmaUrlFilter"
              type="text"
            />
          </div>
        </div>
      </div>
      <div
        class="table__row"
        v-for="item in filteredProjectList"
        :key="item.client_id"
      >
        <div class="table__item">
          <p class="table__text">{{ item?.project_type }}</p>
        </div>
        <div class="table__item">
          <p class="table__text">{{ item?.project_id }}</p>
        </div>
        <div class="table__item">
          <p
            @click="copyLink(item?.figma_link)"
            class="table__text table__text--pointer"
          >
            {{ item?.figma_link }}
          </p>
        </div>
        <div class="table__item table__item--end">
          <button @click="unlinkProject(item._id)" class="table__btn">
            {{ $t("table.unlinkBtn") }}
          </button>
        </div>
      </div>

      <div class="table__row" v-if="isLoading">
        <div class="table__item">
          <DataLoading />
        </div>
      </div>

      <button @click="page++" class="table__btn table__btn--mt12">
        {{ $t("table.moreBtn") }}
      </button>
    </div>
  </section>
</template>

<script setup>
import SyncHeader from "@/components/SyncHeader.vue";
import { queryDelete, queryGet } from "@/api/config";
import { ref, watch } from "vue";
import DataLoading from "@/components/DataLoading.vue";

const copyLink = (text) => {
  try {
    navigator.clipboard.writeText(text);
  } catch (err) {
    console.log(err);
  }
};

const isLoading = ref(true);
const projectsList = ref([]);
const filteredProjectList = ref([]);
const objectIdFilter = ref("");
const figmaUrlFilter = ref("");
const page = ref(0);
const portalDomain = ref(null);

try {
  window.BX24.init(async function () {
    portalDomain.value = window.BX24.getDomain();
    console.log(portalDomain.value);
  });
} catch (error) {
  portalDomain.value = "b24-tpalhm.bitrix24.ru";
  console.log(error);}

const fitWindow = async () => {
  try {
    window.BX24.fitWindow();
    console.log('fitWindow()');
  } catch (error) {
    // Если возникла ошибка, просто пропускаем её без обработки
    console.error('Ошибка при вызове window.BX24.fitWindow():');
  }
};

watch(
  [objectIdFilter, figmaUrlFilter],
  (
    [newObjectFilter, newFigmaUrlFilter],
    [oldObjectFilter, oldFigmaUrlFilter],
  ) => {
    filteredProjectList.value = projectsList.value;

    if (newFigmaUrlFilter && newFigmaUrlFilter !== oldFigmaUrlFilter) {
      filteredProjectList.value = projectsList.value.filter((el) =>
        el.figma_link.includes(newFigmaUrlFilter),
      );
    }

    if (newObjectFilter && newObjectFilter !== oldObjectFilter) {
      filteredProjectList.value = projectsList.value.filter((el) =>
        el.project_link.includes(newObjectFilter),
      );
    }
  },
);

watch(page, (newValue) => {
  isLoading.value = true;
  getProjectsList(newValue);
});

const getProjectsList = async (page) => {
  const res = await queryGet("/synchronization/", {
    params: {
      domain: portalDomain.value,
      limit: 5,
      skip: !page ? 0 : page * 5,
    },
  });
  if (res.status === 200) {
    if (!page) {
      projectsList.value = [...res.data];
    } else {
      projectsList.value = projectsList.value.concat(res.data);
    }

    filteredProjectList.value = [...projectsList.value];
    isLoading.value = false;
    await fitWindow();
  } else {
    isLoading.value = false;
  }

};

const unlinkProject = async (id) => {
  const res = await queryDelete(`/synchronization/${id}`);
  if (res.status === 200 && res.data.success) {
    await getProjectsList(0);
    await fitWindow();
  }
};

getProjectsList(0);
</script>

<style scoped>
.container {
  width: 90%;
  margin: auto;
  min-height: 100vh;
}
.table {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 16px;
  border: 1px solid #232f3f;
  background: rgb(0, 111, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 111, 226, 1) 0%,
    rgba(1, 33, 168, 1) 100%
  );
  box-sizing: border-box;

  padding: 20px 24px;
}

.table__row {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 40% 20%;
  border-bottom: 1px solid #ffffff;
}

.table__item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 8px;
}

.table__item--end {
  align-items: end;
}

.table__item-wrapper {
  position: relative;
  width: 90%;
  height: 40px;
  margin-bottom: 12px;
  border-radius: 4px;
}

.table__item-wrapper:after {
  content: url("../assets/img/search.svg");
  position: absolute;
  height: 80%;
  width: 40px;
  margin-right: 2px;
  top: 15%;
  background-color: #ffffff;
  right: 0;
}

.table__item-input {
  box-sizing: border-box;
  position: relative;
  padding-block: 0;
  padding-inline: 0;
  width: 100%;
  height: 100%;
  outline: none;
  padding-left: 10px;
  border-radius: 4px;
  border: 2px solid #c6cdd3;
}

.table__text {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 12px 8px;
  text-align: start;
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.table__text--pointer {
  cursor: pointer;
}

.table__btn {
  margin: auto;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background-color: inherit;
  outline: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}

.table__btn--mt12 {
  margin-top: 12px;
}
</style>
