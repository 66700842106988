<template>
  <img class="load-icon" src="../assets/img/loader.svg" alt="loading..." />
</template>
<style scoped>
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.load-icon {
  animation: rotate 1s linear infinite;
  width: 24px;
}
</style>
