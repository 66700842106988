<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.27203 4.24268L12.7573 12.728"
      stroke="#C6CDD3"
      stroke-width="2"
      stroke-linecap="square"
    />
    <path
      d="M4.27203 12.728L12.7573 4.24275"
      stroke="#C6CDD3"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>
