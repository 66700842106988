<template>
  <section class="container">
    <div class="form">
<!--      :disabled="figmaUrl === null"-->
      <input

        v-model="figmaUrl"
        type="text"
        class="form__input"
      />
      <button @click="unlinkProject()" class="form__btn" v-if="item">
        {{ $t("synchronizationItem.unlinkBtn") }}
      </button>
      <button @click="linkProject()" class="form__btn" v-else>
        {{ $t("synchronizationItem.linkBtn") }}
      </button>
    </div>
    <iframe
      v-if="frameLink"
      class="figma-frame"
      :src="frameLink"
      allowfullscreen
    ></iframe>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { queryDelete, queryGet, queryPost } from "@/api/config";
// https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fdesign%2FwOVuIFvFR5j9kSKxAUWbUZ%2FPortalSynchronization%3Fnode-id%3D0-1%26t%3DBoQ3CgCPs118KMqr-1
const figmaUrl = ref(null);
const frameLink = ref("");

const projectId = ref(null);
const projectType = ref("");

const item = ref(null);
const portalDomain = ref(null);

try {
  window.BX24.init(async function () {
    portalDomain.value = window.BX24.getDomain();
    console.log(portalDomain.value);
  });
} catch (error) {
  portalDomain.value = "b24-tpalhm.bitrix24.ru";
  console.log(error);}

if (portalDomain.value) {
  console.log("b24")
  try {
    window.BX24.init(async function () {
      projectId.value = window.BX24.placement.info();
      projectType.value = this.placement.options.ID;
      console.log(projectId.value);
    });
  } catch (error) {
    projectId.value = 12345;
    projectType.value = "deal";
    console.log(error);
  }
}
console.log(projectId.value);
console.log(projectType.value);

const fitWindow = async () => {
  try {
    window.BX24.fitWindow();
    console.log('fitWindow()');
  } catch (error) {
    // Если возникла ошибка, просто пропускаем её без обработки
    console.error('Ошибка при вызове window.BX24.fitWindow():');
  }
};

const getItem = async () => {
  const res = await queryGet("/synchronization/", {
    params: {
      domain: portalDomain.value,
      project_id: projectId.value,
      limit: 5,
      skip: 0,
    },
  });
  if (res.status === 200) {
    if (res.data.length) {
      item.value = res.data[0];
      figmaUrl.value = item?.value?.figma_link;
      frameLink.value = figmaUrl.value;
    } else {
      figmaUrl.value = "";
    }
    await fitWindow();
  }
};

const linkProject = async () => {
  if (!figmaUrl.value) return;
  const res = await queryPost(
    "/synchronization/",
    {
      project_type: projectType.value,
      project_id: projectId.value.toString(),
      project_link: `https://b24-1aoujy.bitrix24.ru/crm/deal/details/${projectId.value}`,
      figma_link: figmaUrl.value,
    },
    {
      params: {
        domain: portalDomain.value,
      },
    },
  );
  if (res.status === 200) {
    await getItem();
    await fitWindow();
  }
};

const unlinkProject = async () => {
  if (!figmaUrl.value) return;
  const res = await queryDelete(`/synchronization/${item.value._id}`);
  if (res.status === 200 && res.data.success) {
    figmaUrl.value = null;
    frameLink.value = "";
    await getItem();
    await fitWindow();
  }
};

getItem();

</script>

<style scoped>
.container {
  width: 90%;
  margin: auto;
  min-height: 100vh;
}

.figma-frame {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 600px;
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.form__input {
  width: 100%;
  padding: 13px 10px;
  margin-right: 8px;
  border: 1px solid #c6cdd3;
  border-radius: 28px;
  outline: none;
  transition: 0.05s;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.form__btn::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.form__input:focus {
  border: 2px solid #c6cdd3;
}

.form__btn {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 28px;
  padding: 0 10px;
  cursor: pointer;
  background-color: #14bfd5;
  transition: 0.3s;
}

.form__btn:hover {
  background-color: #43ccde;
}
</style>
